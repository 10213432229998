// Imports

import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { beginLoading, endLoading } from "../../actions/ui";
import contactFormStyles from "./form-contact.module.css";
import { createMessage } from '../../actions/messages';


// Contact Form

class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.reset = this.reset.bind(this)
  }

  static propTypes = {
    createMessage: PropTypes.func.isRequired
  }

  initialState() {
    return {
      _replyto: "",
      name: "",
      phone: "",
      company: "",
      message: "",
    }
  }

  reset() {
    this.setState(this.initialState());
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  submitForm(ev) {
    ev.preventDefault();
    const options = {
      title: 'Submitting',
      subtitle: 'Please be patient while we submit your information.'
    }
    this.props.beginLoading(options);
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      this.props.createMessage({ SUCCESS: "Your information was received. We'll be in touch!"})
      if (xhr.readyState !== 4) return;
      if (xhr.status === 200) {
        this.props.createMessage({ customerReceived: "Your information was received. We'll be in touch!"})
        this.reset();
      }
      this.props.endLoading();
    };
    xhr.send(data);
  }

  render() {
    return (
      <form onSubmit={this.submitForm} action="https://formspree.io/mvoolnee"  method="POST" className={contactFormStyles.contactForm}>
        <div className={contactFormStyles.form__row}>
          <h3 className={contactFormStyles.title}>Contact Us</h3>
        </div>
        <div className={contactFormStyles.form__row}>
          <div className={contactFormStyles.form__input}>
            <label className={contactFormStyles.form__label}>Name</label>
            <input className={contactFormStyles.form__field}
              value={this.state.name}
              name="name"
              type="text"
              placeholder="" 
              required
              onChange={this.handleChange}/>
          </div>
          <div className={contactFormStyles.form__input}>
            <label className={contactFormStyles.form__label}>Email</label>
            <input className={contactFormStyles.form__field}
              value={this.state._replyto}
              name="_replyto"
              type="text"
              placeholder="" 
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={contactFormStyles.form__row}>
          <div className={contactFormStyles.form__input}>
            <label className={contactFormStyles.form__label}>Phone</label>
            <input className={contactFormStyles.form__field}
              value={this.state.phone}
              name="phone"
              type="text"
              placeholder="" 
              required
              onChange={this.handleChange}/>
          </div>
          <div className={contactFormStyles.form__input}>
            <label className={contactFormStyles.form__label}>Company</label>
            <input className={contactFormStyles.form__field}
              value={this.state.company}
              name="company"
              type="text"
              placeholder=""
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={contactFormStyles.form__row}>
          <div className={contactFormStyles.form__input}>
            <label className={contactFormStyles.form__label}>Message</label>
            <textarea className={contactFormStyles.form__textarea}
              value={this.state.message}
              name="message"
              type="text"
              placeholder="How can we help you?" 
              required
              onChange={this.handleChange}/>
          </div>
        </div>
        <div className={contactFormStyles.form__buttons}>
          <button className={contactFormStyles.form__buttonSubmit} type="submit">
            Submit
          </button>
          <button className={contactFormStyles.form__buttonReset} type="button" onClick={this.reset}>
            Clear All Fields
          </button>
        </div>
      </form>
    )
  }
}


export default connect(
  null, 
  { createMessage, beginLoading, endLoading }
)(ContactForm);