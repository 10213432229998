// Imports

import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactForm from "../components/form-contact/form-contact"
import contactPageStyles from "../styles/pages/contact.module.css"
import { FaEnvelope, FaPhone, FaFax, FaBuilding } from "react-icons/fa"

// Contact

const ContactPage = ({ data }) => {
  const contactInfo = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <SEO title="ACE | Nevada's Leading Cannabis Testing Lab" />
      <div className={contactPageStyles.backgroundContainer}></div>
      <div className={contactPageStyles.outerContainer}>
        <div className={contactPageStyles.innerContainer}>
          <div className={contactPageStyles.form__container}>
            <ContactForm />
          </div>
          <div className={contactPageStyles.contact}>
            <h3 className={contactPageStyles.title}>Contact Information</h3>
            <a href={"mailto:" + contactInfo.email} className={contactPageStyles.email}>
              <FaEnvelope />
              <span className={contactPageStyles.span}>{contactInfo.email}</span>
            </a>
            <a href={"tel:" + contactInfo.phone} className={contactPageStyles.phone}>
              <FaPhone />
              <span className={contactPageStyles.span}>{contactInfo.phone}</span>
            </a>
            <a href={"tel:" + contactInfo.fax} className={contactPageStyles.fax}>
              <FaFax />
              <span className={contactPageStyles.span}>{contactInfo.fax}</span>
            </a>
            <div className={contactPageStyles.address__container}>
              <FaBuilding />
              <div className={contactPageStyles.address}>
                <p className={contactPageStyles.address__title}>{contactInfo.addressLine1}</p>
                <p className={contactPageStyles.address__detail}>{contactInfo.addressLine2}<br/> {contactInfo.city}, {contactInfo.state} {contactInfo.zip}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "contact-page"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              email
                phone
                fax
                addressLine1
                addressLine2
                city
                state
                zip
          }
        }
      }
    }
  }
}`
